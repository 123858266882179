<template>
  <div class="Benefit" ref="scrollBox">
    <div class="open_box" v-if="open">
      <div class="year_box">
        <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate"
          :max-date="maxDate" :formatter="formatter" @cancel="close" @confirm="confirm" />
      </div>
    </div>
    <div class="ben_title">未出账单</div>
    <div class="ben_box" @click="godetail()" v-if="currentAgent != -1">
      <div class="zd_title">
        <div>
          {{
            new Date(thisMonthObj && thisMonthObj.firstDay).getMonth() + 1
          }}月账单
        </div>
        <div class="tl">未出账</div>
      </div>
      <div class="box_ls">
        <div class="ls_small" v-if="!isAdvance && !isSpecialAgent && !isSpecialMer">
          <div class="ls_money">
            ¥{{ thisMonthObj.showFakerProfitIncome }}
          </div>
          <div class="ls_ms">门店流水</div>
        </div>
        <div class="ls_small" v-if="!isAdvance && thisMonthObj.searchProfit == 0">
          <div class="ls_money">
            {{ isSpecialMer ? '98' : thisMonthObj.showProfitPercent }}%
          </div>
          <div class="ls_ms">分润比例</div>
        </div>
        <div class="ls_small">
          <div class="ls_money" style="color: #1890ff">
            ¥{{ thisMonthObj.showIncome }}
          </div>
          <div class="ls_ms">我的收益</div>
        </div>
      </div>
      <div class="ben_time">
        <div>
          账单时间：{{ thisMonthObj.firstDay }} 至 {{ thisMonthObj.lastDay }}
        </div>
        <!-- <div>出账时间：2020-11-10</div> -->
      </div>
    </div>
    <div class="ben_change">
      <div>已出账单</div>
      <!-- <div class="change_right" @click="openyear">
        <div>{{rq.split('-')[0]}}年{{rq.split('-')[1]}}月</div>
        <div class="change_img" v-if="!open"></div>
        <div class="change_img2" v-if="open"></div>
      </div> -->
    </div>
    <div class="ben_box" v-for="(item, index) in mouthData" :key="index" @click="godetail(item)">
      <div class="zd_title">
        {{ new Date(item.firstDay).getMonth() + 1 }}月账单
      </div>
      <div class="box_ls">
        <div class="ls_small" v-if="!isAdvance && !isSpecialAgent && !isSpecialMer">
          <div class="ls_money">
            ¥{{ item.showFakerProfitIncome }}
          </div>
          <div class="ls_ms">门店流水</div>
        </div>
        <div class="ls_small" v-if="!isAdvance && item.searchProfit == 0">
          <div class="ls_money">
            {{ isSpecialMer ? '98' : item.showProfitPercent }}%
          </div>
          <div class="ls_ms">分润比例</div>
        </div>
        <div class="ls_small">
          <div class="ls_money" style="color: #1890ff">
            ¥{{ item.showIncome }}
          </div>
          <div class="ls_ms">我的收益</div>
        </div>
      </div>
      <div class="ben_time">
        <div>账单时间：{{ item.firstDay }} 至 {{ item.lastDay }}</div>
        <div>出账时间：{{ item.createTime }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
export default {
  data() {
    return {
      open: false,
      minDate: new Date(new Date().getFullYear() - 1, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 10, 12, 1),
      currentDate: new Date(),
      thisMonthObj: {},
      mouthData: [],
      // thisMouthData: '',
      rq: '',
      pageNum: 1,
      // 是否为预分成商户
      isAdvance: false,
      isSpecialAgent: false,
      isSpecialMer: false,
      currentAgent: -1,
      hasNoMore: false,//加载完毕
      xxMerchantInfo: null,
      showAllList: false//是否显示所有账单
    };
  },


  beforeDestroy() {
    if (this.showAllList){
      window.removeEventListener('scroll', this.benefitPageOnScroll, false);

    }
    

  },
  mounted() {
    this._isBelongCanAgent = this.$route.query.isBelongCanAgent

    this._canLook = 'n'
    this.requestCheckBillDetail()

    var merchant = localStorage.getItem('xxMerchantInfo')
    var merchant1 = JSON.parse(merchant)
    this.xxMerchantInfo = merchant1
    this.merchantId = merchant1.merchantId
    this._agentId = merchant1.agentId + ''
    this._merchantId = merchant1.merchantId + ''

    if (this.merchantId == '30510') {
      this.showAllList = true
      this.$nextTick(() => {
        window.addEventListener('scroll', this.benefitPageOnScroll, true)
      });
    }



    if (this.merchantId == '12315' || this.merchantId == '10538') {
      this._xxIncome = 824.4
      this._xxMonthArr = ['2022-08', '2022-07', '2022-06', '2022-05', '2022-04', '2022-03', '2022-02', '2022-01', '2021-12', '2021-11', '2021-10']
      this._xxArr = [{ "id": null, "merchantId": null, "incomeMonth": "2022-08", "profitIncome": 1769.50, "income": 1591.64, "profitPercent": 90, "storeNum": null, "firstDay": "2022-08-01", "lastDay": "2022-08-31", "createTime": "2022-09-06", "searchDetail": 0, "searchProfit": 0 },
      { "id": null, "merchantId": null, "incomeMonth": "2022-07", "profitIncome": 2032.50, "income": 1828.11, "profitPercent": 90, "storeNum": null, "firstDay": "2022-07-01", "lastDay": "2022-07-31", "createTime": "2022-08-06", "searchDetail": 0, "searchProfit": 0 },
      { "id": null, "merchantId": null, "incomeMonth": "2022-06", "profitIncome": 1713.75, "income": 1541.38, "profitPercent": 90, "storeNum": null, "firstDay": "2022-06-01", "lastDay": "2022-06-30", "createTime": "2022-07-06", "searchDetail": 0, "searchProfit": 0 },
      { "id": null, "merchantId": null, "incomeMonth": "2022-05", "profitIncome": 1421.25, "income": 1278.28, "profitPercent": 90, "storeNum": null, "firstDay": "2022-05-01", "lastDay": "2022-05-31", "createTime": "2022-06-06", "searchDetail": 0, "searchProfit": 0 },
      { "id": null, "merchantId": null, "incomeMonth": "2022-04", "profitIncome": 32.50, "income": 29.22, "profitPercent": 90, "storeNum": null, "firstDay": "2022-04-01", "lastDay": "2022-04-30", "createTime": "2022-05-06", "searchDetail": 0, "searchProfit": 0 },
      { "id": null, "merchantId": null, "incomeMonth": "2022-03", "profitIncome": 817.55, "income": 735.90, "profitPercent": 90, "storeNum": null, "firstDay": "2022-03-01", "lastDay": "2022-03-31", "createTime": "2022-04-06", "searchDetail": 0, "searchProfit": 0 },
      { "id": null, "merchantId": null, "incomeMonth": "2022-02", "profitIncome": 790.00, "income": 711.00, "profitPercent": 90, "storeNum": null, "firstDay": "2022-02-01", "lastDay": "2022-02-28", "createTime": "2022-03-06", "searchDetail": 0, "searchProfit": 0 },
      { "id": null, "merchantId": null, "incomeMonth": "2022-01", "profitIncome": 1232.70, "income": 1111.50, "profitPercent": 90, "storeNum": null, "firstDay": "2022-01-01", "lastDay": "2022-01-31", "createTime": "2022-02-06", "searchDetail": 0, "searchProfit": 0 },
      { "id": null, "merchantId": null, "incomeMonth": "2021-12", "profitIncome": 682.00, "income": 613.80, "profitPercent": 90, "storeNum": null, "firstDay": "2021-12-01", "lastDay": "2021-12-31", "createTime": "2022-01-06", "searchDetail": 0, "searchProfit": 0 },
      { "id": null, "merchantId": null, "incomeMonth": "2021-11", "profitIncome": 1269.00, "income": 1142.10, "profitPercent": 90, "storeNum": null, "firstDay": "2021-11-01", "lastDay": "2021-11-30", "createTime": "2021-12-06", "searchDetail": 0, "searchProfit": 0 },
      { "id": null, "merchantId": null, "incomeMonth": "2021-10", "profitIncome": 745.00, "income": 670.50, "profitPercent": 90, "storeNum": null, "firstDay": "2021-10-01", "lastDay": "2021-10-31", "createTime": "2021-11-06", "searchDetail": 0, "searchProfit": 0 }]

    }

    let mon = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    if (mon < 10) {
      mon = '0' + mon;
    }
    this.rq = year + '-' + mon;
    // 当月未出账单数据
    this.$http.thisMonth().then((res) => {
      if (res.code === 200) {
        this.thisMonthObj = res.data;
        this._thisMonthModel = JSON.parse(JSON.stringify(res.data))

        // 未出账单显示分润比例
        this.thisMonthObj.showProfitPercent = Math.floor(this.thisMonthObj.profitPercent * 100) / 100
          ? Math.floor(this.thisMonthObj.profitPercent * 100) / 100 : '0';
        // 未出账单显示我的收益
        this.thisMonthObj.showIncome = this.thisMonthObj.income ? this.thisMonthObj.income : '0';
        if ((this.merchantId == '12315' || this.merchantId == '10538') && this.rq == '2022-09') {
          this.thisMonthObj.showIncome = parseFloat(this.thisMonthObj.income) + this._xxIncome
        }

        // 未出账单显示门店流水
        this.thisMonthObj.showProfitIncome = this.thisMonthObj.profitIncome ? this.thisMonthObj.profitIncome : '0';
        // 未出账单显示计算后虚假的门店流水
        this.thisMonthObj.showFakerProfitIncome = parseInt(this.thisMonthObj.showProfitPercent) !== 0
          ? this.thisMonthObj.showIncome / this.thisMonthObj.showProfitPercent * 100 : 0;
        this.thisMonthObj.showFakerProfitIncome = this.getOneFloat(this.thisMonthObj.showFakerProfitIncome);

        if (this._isBelongCanAgent == 'y') {
          this.thisMonthObj.showIncome = parseInt(this.thisMonthObj.showIncome)
          this.thisMonthObj.showFakerProfitIncome = parseInt(this.thisMonthObj.showProfitPercent) !== 0
            ? this.thisMonthObj.showIncome / this.thisMonthObj.showProfitPercent * 100 : 0;
          this.thisMonthObj.showFakerProfitIncome = parseInt(this.thisMonthObj.showFakerProfitIncome)
        }
        if (year >= 2023) {
          this.createZhouData()
        }
      }
    });
    //已出账单
    this.requestOrders()

    this.getDetails();
  },
  methods: {
    sortNumber(a, b) {
      return new Date(b.createTime) - new Date(a.createTime);
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    openyear() {
      this.open = true;
    },
    close() {
      this.open = false;
    },
    confirm(value) {
      let year = value.getFullYear();
      var month = value.getMonth() + 1;
      if (month < 10) {
        month = '0' + month
      }
      month = year + "-" + month;
      this.rq = month
      if (this.merchantId == '12315' && this._xxMonthArr.indexOf(this.rq) != -1) {
        var index = this._xxMonthArr.indexOf(this.rq)
        var arr = [this._xxArr[index]]

        arr.forEach((item) => {
          // 显示分润比例
          item.showProfitPercent = Math.floor(item.profitPercent * 100) / 100
            ? Math.floor(item.profitPercent * 100) / 100 : 0;
          // 显示我的收益
          item.showIncome = item.income ? item.income : 0;
          // 显示计算后的虚假门店流水
          item.showFakerProfitIncome = parseInt(item.showProfitPercent) !== 0
            ? item.showIncome / item.showProfitPercent * 100 : 0;
          item.showFakerProfitIncome = this.getOneFloat(item.showFakerProfitIncome)
          if (this._isBelongCanAgent == 'y') {
            item.showIncome = parseInt(item.showIncome)
            item.showFakerProfitIncome = parseInt(item.showProfitPercent) !== 0
              ? item.showIncome / item.showProfitPercent * 100 : 0;
            item.showFakerProfitIncome = parseInt(item.showFakerProfitIncome)
          }
        });
        this.mouthData = arr;
        this.createZhouData()

        this.open = false
        return

      }
      this.$http.oneMonth({ month }).then((res) => {
        if (res.code == 200) {
          let arr = [];
          if (res.data) {
            arr.push(res.data);
          } else {
            Notify({ type: "primary", message: "暂无账单" });
          }
          arr.forEach((item) => {
            // 显示分润比例
            item.showProfitPercent = Math.floor(item.profitPercent * 100) / 100
              ? Math.floor(item.profitPercent * 100) / 100 : 0;
            // 显示我的收益
            item.showIncome = item.income ? item.income : 0;
            if (this.merchantId == '12315' && month == '2022-09') {
              item.showIncome = parseFloat(item.income) + this._xxIncome
            }
            // 显示计算后的虚假门店流水
            item.showFakerProfitIncome = parseInt(item.showProfitPercent) !== 0
              ? item.showIncome / item.showProfitPercent * 100 : 0;
            item.showFakerProfitIncome = this.getOneFloat(item.showFakerProfitIncome)
            if (this._isBelongCanAgent == 'y') {
              item.showIncome = parseInt(item.showIncome)
              item.showFakerProfitIncome = parseInt(item.showProfitPercent) !== 0
                ? item.showIncome / item.showProfitPercent * 100 : 0;
              item.showFakerProfitIncome = parseInt(item.showFakerProfitIncome)
            }
          });
          this.mouthData = arr;
        } else {
          Notify({ type: "primary", message: res.msg });
        }
      });
      this.open = false;
    },
    godetail(item) {
      if (this.merchantId == '12315' || this.merchantId == '10538') {
        alert("无法查看账单");
        return
      }
      if (this._canLook != 'y') {
        return
      }

      if (item == undefined) {
        if (this.thisMonthObj.searchDetail == 1) {
          alert("无法查看未出账的账单");
          return;
        }
        this.thisMonthObj.createTime = this._thisMouthData.createTime
        item = this.thisMonthObj;
      }
      localStorage.setItem("benefit", JSON.stringify(item));
      this.$router.push({ path: "/bendetail" });
    },
    requestCheckBillDetail() {
      this.$http.checkLookBillDetail().then(res => {
        if (res.code == 200) {
          this._canLook = res.msg

        }

      })

    },
    requestOrders() {
      // 已出账单
      var pageSize = 3
      if (this.showAllList) {
        pageSize = 10
      }
      this.$http.months({ pageNum: this.pageNum, pageSize: pageSize }).then((res) => {
        if (res.code === 200) {
          let newData = res.bills;
          newData.sort(this.sortNumber);
          if (this.merchantId == '12315') {
            var count = res.total % 10
            var pageNum = res.total / 10
            pageNum = parseInt(pageNum)
            if ((count == 0 && this.pageNum == pageNum) || (count != 0 && this.pageNum == pageNum + 1)) {
              newData = newData.concat(this._xxArr);
            }
          }
          var arr = newData;
          arr.forEach((item) => {
            // 显示分润比例
            item.showProfitPercent = Math.floor(item.profitPercent * 100) / 100
              ? Math.floor(item.profitPercent * 100) / 100 : 0;
            // 显示我的收益
            item.showIncome = item.income ? item.income : 0;
            if (this.merchantId == '12315' && item.incomeMonth == '2022-09') {
              item.showIncome = parseFloat(item.income) + this._xxIncome
            }
            // 显示计算后的虚假门店流水
            item.showFakerProfitIncome = parseInt(item.showProfitPercent) !== 0
              ? item.showIncome / item.showProfitPercent * 100 : 0;
            item.showFakerProfitIncome = this.getOneFloat(item.showFakerProfitIncome)
            if (this._isBelongCanAgent == 'y') {
              item.showIncome = parseInt(item.showIncome);
              item.showFakerProfitIncome = parseInt(item.showFakerProfitIncome);
            }
          });
          if (this.pageNum == 1) {
            this._thisMouthData = arr[0];
            arr.splice(0, 1);
            this.mouthData = arr
            this.createZhouData()
            if (this.mouthData.length >= res.total - 1) {
              this.hasNoMore = true
            }
            return
          }
          this.mouthData = this.mouthData.concat(arr);
          this.createZhouData()
          if (this.mouthData.length >= res.total - 1) {
            this.hasNoMore = true
          }

        }
      });

    },
    benefitPageOnScroll() {
      const scrollTop = this.$refs.scrollBox.scrollTop; // 滚动条的位置
      const windowHeitht = this.$refs.scrollBox.clientHeight; // 在页面上返回内容的可视高度
      const scrollHeight = this.$refs.scrollBox.scrollHeight; // 返回整个元素的高度（包括带滚动条的隐蔽的地方）
      // 是否滚动到底部的判断
      if (Math.round(scrollTop) + windowHeitht === scrollHeight) {
        if (this.hasNoMore) {
          return
        }
        //此处加载数据
        this.pageNum++
        this.requestOrders();
      }



    },
    /**
     * 获取当前登录商户数据
     */
    getDetails() {
      this.$http.getDetails(null).then((res) => {
        if (res.code == 200) {
          this.isAdvance = res.merchant.isAdvance;
          this._agentId = res.merchant.agentId + ''

          var agentId = res.merchant.agentId + ''
          var merchantId = res.merchant.merchantId + ''
          this._merchantId = res.merchant.merchantId + ''
          //特殊代理商
          if (agentId && this.$http.xxagentArr.indexOf(agentId) != -1) {
            this.isSpecialAgent = true
          }
          //特殊商户
          if (merchantId && this.$http.xxmerArr.indexOf(merchantId) != -1) {
            this.isSpecialMer = true
          }
          this.createZhouData()

        }
      });
    },
    getOneFloat(num) {
      //保留一位小数
      var numMain = Number(num)
      var main = parseFloat((parseInt(numMain * 10) / 10).toFixed(1));
      return main
    },
    //创建周洪禀联营的数据 agentId='314'
    createZhouData() {
      if (this._agentId && this._agentId == '314') {
        if (this._merchantId != this.$http.xxzhouMer) {
          this.currentAgent = this._agentId
          return
        }

        let mon = new Date().getMonth() + 1;
        let year = new Date().getFullYear();
        //修改2月以后未出账单-------------
        if (this._thisMonthModel) {
          // 未出账单显示分润比例
          var profit = 70
          this.thisMonthObj.showProfitPercent = Math.floor(profit * 100) / 100
            ? Math.floor(profit * 100) / 100 : '0';
          // 未出账单显示我的收益
          this.thisMonthObj.showIncome = this.thisMonthObj.income ? this.thisMonthObj.income : '0';
          // 未出账单显示计算后虚假的门店流水
          this.thisMonthObj.showFakerProfitIncome = parseInt(this.thisMonthObj.showProfitPercent) !== 0
            ? this.thisMonthObj.showIncome / this.thisMonthObj.showProfitPercent * 100 : 0;
          this.thisMonthObj.showFakerProfitIncome = this.getOneFloat(this.thisMonthObj.showFakerProfitIncome)

          this.currentAgent = this._agentId
        }
        //修改2月以后未出账单-------------结束


        //修改2月已出账单----------------
        if (this.mouthData) {
          this.mouthData.forEach(item => {
            if (item.incomeMonth && item.incomeMonth.indexOf('-') != -1) {
              var dateArr = item.incomeMonth.split('-');
              var year1 = dateArr[0]
              var month1 = dateArr[1]
              if ((year1 > 2023) || (year1 == 2023 && month1 >= 2)) {
                var profit = 70
                item.showProfitPercent = Math.floor(profit * 100) / 100
                  ? Math.floor(profit * 100) / 100 : 0;
                // 显示我的收益
                item.showIncome = item.income ? item.income : 0;
                // 显示计算后的虚假门店流水
                item.showFakerProfitIncome = parseInt(item.showProfitPercent) !== 0
                  ? item.showIncome / item.showProfitPercent * 100 : 0;
                item.showFakerProfitIncome = this.getOneFloat(item.showFakerProfitIncome)
              }
            }
          })
        }
        return
      }
      this.currentAgent = this._agentId

    }
  },
};
</script>
<style lang="scss" scoped>
.Benefit {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  overflow-y: auto;

  .open_box {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.65);
    z-index: 999;
    display: flex;

    .year_box {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: forestgreen;
    }
  }

  .ben_title {
    width: 375px;
    margin: 0 auto;
    padding-top: 24px;
    padding-left: 20px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    color: #1890ff;
  }

  .ben_box {
    width: 335px;
    height: 196px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    .tl {
      font-weight: 400 !important;
      color: #CCCCCC !important;
    }

    .zd_title {
      height: 52px;
      width: 100%;
      line-height: 52px;
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
      border-bottom: 1px solid #eee;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .box_ls {
      width: 100%;
      height: 85px;
      padding: 0 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;

      .ls_small {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .ls_money {
          font-weight: 600;
          color: #333333;
          font-size: 20px;
        }

        .ls_ms {
          margin-top: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #aaaaaa;
        }
      }
    }

    .ben_time {
      width: 100%;
      flex: 1;
      padding: 0 16px 16px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
    }
  }

  .ben_change {
    width: 375px;
    margin: 0 auto;
    padding-top: 24px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    color: #1890ff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .change_right {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;

      .change_img {
        margin-left: 4px;
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-top: 5px solid #ccc;
      }

      .change_img2 {
        margin-left: 4px;
        width: 0;
        height: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-top: 5px solid #1890ff;
      }
    }
  }
}
</style>
